import { AddressResponse } from "../address-response";
import { BusinessHoursHelper } from "../utils/business-hours-helper";
import { BusinessHours } from "./business-hours";
import { DeliveryTimeRangeEnum } from "./delivery-time-range-enum";
import { RestaurantCategoryEnum } from "./restaurant-category-enum";

export class RestaurantResponse {
    
    public id: string = "";
    
    public address: AddressResponse = new AddressResponse();    
    public businessHoursDelivery: BusinessHours = new BusinessHours();
    public businessHoursPickup: BusinessHours = new BusinessHours();
    public closed: boolean = true;
    public companyName: string = "";
    public categoryList: RestaurantCategoryEnum[] = [];
    public deliveryTimeRange: DeliveryTimeRangeEnum = DeliveryTimeRangeEnum.D45_60_MIN;
    public deliveryRadiusMeters: number = 1500; // default   
    public description: string = "";
    public documents: DocumentResponse[] = [];
    public email: string | null = null; // api expects it as null instead of "";
    public hasOnlinePayment: boolean = false;
    public hasOwnDelivery: boolean = false;
    public hasFoodisDelivery: boolean = false;
    public hasPickup: boolean = false;
    public hasPreorder: boolean = false;
    public ibanAccount: string | null = null;
    public invoiceAddress: AddressResponse = new AddressResponse();
    public logoImageUrl?: string;
    public logoImageBlobId?: string;
    public mainImageUrl?: string;
    public mainImageBlobId?: string;
    public minOrderAmount: number = 0.0;
    public name: string = "";
    public phoneNumber: string = "";
    public preorderEnabled: boolean = false;
    public slug: string = "";
    public vatNumber: string = "";
    public verificationMessage: string = "";
    public verificationStatus: RestaurantVerificationEnum = RestaurantVerificationEnum.NEW;
    public website: string = "";    
    public timeZone: string = BusinessHoursHelper.DEFAULT_TIMEZONE_VIENNA;
    public stripeAccountId: string = "";

    constructor() {
    }
}

export enum RestaurantVerificationEnum {
    NEW = "NEW",
    VERIFICATION_REQUESTED = "VERIFICATION_REQUESTED",
    VERIFICATION_DECLINED = "VERIFICATION_DECLINED",
    VERIFIED = "VERIFIED"
}

export class DocumentResponse {
    id: string | null = "";
    name: string = "";
    url: string = "";
    blobId: string = "";
}