import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'apps/admin/src/environments/environment';
import { BusinessHoursNameEnum } from 'libs/shared-models/src/lib/restaurant/business-hours';
import { RestaurantResponse, RestaurantVerificationEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-response';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'restaurants-list',
  templateUrl: './restaurants-list.component.html',
  styleUrls: ['./restaurants-list.component.scss'],
})
export class RestaurantsListComponent implements OnInit {

  public restaurantsList$: BehaviorSubject<RestaurantResponse[]> = new BehaviorSubject<RestaurantResponse[]>([]);
  
  // Map to hold the expanded state of each restaurant
  public expandedMap: Map<string, boolean> = new Map<string, boolean>();

  public verificationMessageInputMap: Map<string, string> = new Map<string, string>();

  public RestaurantVerificationEnum: typeof RestaurantVerificationEnum = RestaurantVerificationEnum;

  public restaurantSearchInput: string = "";	

  // filter
  public verificationStatus = new FormControl('ALL');
  
  constructor(
    private apiService: ApiBaseService,
    private toasterService: ToasterService,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.fetchRestaurantsList();    

    this.restaurantsList$.subscribe(restaurants => {
      restaurants.forEach(restaurant => {
        if (!this.expandedMap.has(restaurant.id)) {
          this.expandedMap.set(restaurant.id, false); // Default to collapsed
        }

        if (restaurant.verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED 
            && !this.verificationMessageInputMap.has(restaurant.id)) {
              this.verificationMessageInputMap.set(restaurant.id, "");
        }
      });
    });
  }


  public onMessageChange($event: any, restaurantId: string = "") {
    this.expandedMap.set(restaurantId, $event);
  }

  // Approve restaurant
  public onApproveClick(restaurantId: string = "") {
    const url =  environment.API_VERIFICATION_APPROVE.replace("{restaurantId}", restaurantId);

    this.apiService.post(url, {
      message: this.verificationMessageInputMap.get(restaurantId)
    }).subscribe({
        next: (res: any) => {
            this.toasterService.showSuccess("Approved", "Restaurant " + restaurantId + " was approved");
        },
        error: (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        }
    });
  }

  // Reject restaurant
  public onRejectClick(restaurantId: string = "") {        
    const url = environment.API_VERIFICATION_DECLINE.replace("{restaurantId}", restaurantId);    
    
    this.apiService.post(url, {
      message: this.verificationMessageInputMap.get(restaurantId)
    }).subscribe({
        next: (res: any) => {
            this.toasterService.showInfo("Declined", "Restaurant " + restaurantId + " was rejected");
        },
        error: (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        }
    });
  }
  
  // Restaurants list
  private fetchRestaurantsList() {

    // not really usable - as it's not showing all restaurants which are created / pending / rejected. Only approved ones for now
    let url = environment.API_RESTAURANTS_SEARCH;

    if (this.verificationStatus.value !== "ALL") {
      url += "?verificationStatus=" + this.verificationStatus.value;
    }

    if (this.restaurantSearchInput) {
      if (this.verificationStatus.value !== "ALL") {
        url += "&name=" + this.restaurantSearchInput;
      } else {
        url += "?name=" + this.restaurantSearchInput;
      }
      
    }

    this.apiService.get(url).subscribe({
        next: (res: any) => {

            const restaurants = res?.results.map((r: RestaurantResponse) => Object.assign(new RestaurantResponse(), r));
            this.restaurantsList$.next(restaurants);
            
        },
        error: (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        }
    });
  }


  
  public isRestaurantExpanded(id: string): boolean {
    return this.expandedMap.get(id) || false;
  }

  // Function to toggle the expanded state of a restaurant
  public toggleRestaurant(id: string): void {
    const currentState = this.expandedMap.get(id) || false;
    this.expandedMap.set(id, !currentState);
    this.cdRef.detectChanges();
  }

  public getHours(restaurant: RestaurantResponse): any {  
    const pickUpHours = restaurant.businessHoursPickup;
    const deliveryHours = restaurant.businessHoursDelivery;
    const days = [BusinessHoursNameEnum.MONDAY, BusinessHoursNameEnum.TUESDAY, BusinessHoursNameEnum.WEDNESDAY, BusinessHoursNameEnum.THURSDAY, BusinessHoursNameEnum.FRIDAY, BusinessHoursNameEnum.SATURDAY, BusinessHoursNameEnum.SUNDAY];
    const dataToReturn = days.map((item) => {
      return {
        day: item,
        pickupHours: (pickUpHours as any)[item],
        deliveryHours: (deliveryHours as any)[item],
      }
    })
    return dataToReturn;
  }


  // verification status filter
  public filterVerificationStatusChange($event: any) {
    // re-fetch
    this.fetchRestaurantsList();
  }


  public clearRestaurantSearchPress() {
    this.onSearchRestaurantUpdate("");
  }
  
  public onSearchRestaurantUpdate(value: string) {
    if (this.restaurantSearchInput === value) {
      return;
    }
    this.restaurantSearchInput = value;
    this.fetchRestaurantsList();
  }
}