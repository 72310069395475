export const environment = {
    BUILD_PRODUCT: "admin",
    API_URL_BASE: "https://api.staging.foodis.day/",
    API_LOGIN_URL: "v1/users/login",
    API_USER_SELF: "v1/users/self",
    PRODUCT_URLS: {
        LANDING_PAGE: "https://staging.foodis.day",
        USER_APP: "https://app.staging.foodis.day",
        RESTAURANT: "https://restaurant.staging.foodis.day",
        DRIVER: "https://driver.staging.foodis.day",
        ADMIN: "https://admin.staging.foodis.day",
        ACCOUNT: "https://account.staging.foodis.day"
    },
    API_VERIFICATION_APPROVE: "v1/admin/restaurants/{restaurantId}/accept-verification",
    API_VERIFICATION_DECLINE: "v1/admin/restaurants/{restaurantId}/decline-verification",
    API_RESTAURANTS_SEARCH: "v1/admin/restaurants",
    API_USERS_SEARCH: "v1/admin/users",
    DEBUG_STAGING_BADGE: true
};
