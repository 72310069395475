<!-- All restaurants list -->
<div class="users-list">
    <div class="flex mb-5 mt-10 items-center"> <mat-icon class="mr-2">account_circle</mat-icon> Users list: 
    
        <div class="flex flex-row items-center ml-8">

            <fs-input [label]="'Search '" [style]="'full-width'" class="mr-4 product-search-input"
                [appearanceStyle]="'outline'" [subscriptSizing]="'dynamic'" [floatLabel]="'always'"
                (valueUpdate)="onSearchUserUpdate($event)" [inputValue]="userSearchInput"
                [prefixIcon]="'search'" [trailingIcon]="!!userSearchInput ? 'close' : ''" 
                (trailingIconPress)="clearUserSearchPress()" [keepTrailingIconSpace]="true">
            </fs-input>

            
            <span class="search-by">Search by: </span>
            <fs-menu-simple
                [itemsList]='getSearchTypes()'
                [selectedItem]='selectedSearchType'
                [leadingIcon]="'./libs/shared-ui/assets/icons/arrow-down.svg'"
                (onSelect)="onSelectSearchType($event)">
            </fs-menu-simple>
            
        </div>
    </div>
    <ng-container *ngFor="let user of (usersList$ | async)">

        <!-- Each restaurant -->
        <div class="users-item">
            <!-- Dropdown accordion Business hours-->
            <mat-accordion>                
                <mat-expansion-panel (opened)="toggleUser(user.id)" (closed)="toggleUser(user.id)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>  
                            <div class="expansion-title">
                                {{ user.name }} <span class="header-email">({{ user.email }})</span>
                            </div>
                            
                        </mat-panel-title>
                        <mat-panel-description class="expansion-description">                           
                            
                            
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                                    
                        
                    <!-- 3 columns -->
                    <div class="other-details-container">

                        <!-- Left side -->
                        <div class="other-details-left">
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">ID:</div>
                                <div>
                                    {{ user.id }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">E-mail:</div>
                                <div>
                                    {{ user.email }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Name:</div>
                                <div>
                                    {{ user.name }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Product:</div>
                                <div>
                                    {{ user.product }}
                                </div>
                            </div>  

                            
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Phone number:</div>
                                <div>
                                    {{ user.phoneNumber }}
                                </div>
                            </div>  
                            
                            
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">User Language:</div>
                                <div>
                                    {{ user.userLanguage }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Activated:</div>
                                <div>
                                    {{ user.activated }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Company name:</div>
                                <div>
                                    {{ user.companyName ? user.companyName : "Not provided" }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Created Date:</div>
                                <div>
                                    {{ user.createdDate }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Last modified Date:</div>
                                <div>
                                    {{ user.lastModifiedDate }}
                                </div>
                            </div>  
                        </div>   
                        
                        <!-- Right side -->
                        <div class="other-details-right">
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Addresses:</div>                                  
                            </div>  
                            <div>
                                <ng-container *ngIf="user.addressList.length === 0">
                                    No addresses added
                                </ng-container>
                                <ng-container *ngFor="let address of user.addressList">
                                    <!-- Data -->
                                    <div class="flex mb-4">
                                        <span class="pr-2">
                                            <span *ngIf="address.isDefault"><mat-icon>star</mat-icon></span>                                                          
                                            <span *ngIf="!address.isDefault">⦿</span>
                                        </span>    
                                                                                                      
                                        Street: {{ address?.streetName  }}, Number: {{ address?.streetNumber  }},
                                        Entrance: {{ address.entrance }}, Floor: {{address.floor}}, Door: {{address.doorNumber}}
                                        District:{{ address?.district  }}, Postal: {{ address?.postalCode  }}, City: {{ address?.city  }}, Country: {{ address?.country }}, 
                                        ID: {{ address.id}}, Lat: {{ address.latitude }}, Lng: {{ address.longitude }}, 
                                        isDefault: {{ address.isDefault }}, additional info: {{ address.additionalInfo ? address.additionalInfo : "-" }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                     

                </mat-expansion-panel>
            </mat-accordion>
        </div>                
    </ng-container>
</div>
