import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { Product } from "libs/shared-models/src/lib/product-type";

export class AdminUserResponse {
    id: string = "";
    email: string = "";
    name: string = "";
    userLanguage: string = "";
    product: Product = Product.UNDEFINED;
    companyName: string = "";
    phoneNumber: string = "";
    addressList: AddressResponse[] = [];
    activated: boolean;
    createdDate: string = "";
    lastModifiedDate: string = "";
}