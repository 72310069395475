import { Component } from '@angular/core';
import { SelfAdminService } from './services/self.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'admin-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'admin';

  public isStaging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private selfService: SelfAdminService
  ) {
    this.selfService.fetchSelfDataAPI();

    this.isStaging$.next(environment.DEBUG_STAGING_BADGE);
  }
}
