import { BusinessHours, BusinessHoursNameEnum, BusinessHoursPeriod } from '../restaurant/business-hours';

export class BusinessHoursHelper {

    public static DEFAULT_TIMEZONE_VIENNA = "+01:00"; // the separate CEST (summer time) is calculated with additional +1 (so it ends in +02:00 on calculation)

    public static checkBusinessHoursActive(data: BusinessHoursCheck): boolean {
        return this.checkIsActiveDuringHoursPickup(data) || this.checkIsActiveDuringHoursDelivery(data);
    }

    // direct check
    public static checkIsActiveDuringHoursPickup(data: BusinessHoursCheck): boolean {
        const currentDate = this.getCurrentDate();
        const isWithinPickupHours = this.isWithinBusinessHours(data.businessHoursPickup, currentDate);
        return !data.closed && data.hasPickup && isWithinPickupHours;
    }

    public static checkIsActiveDuringHoursDelivery(data: BusinessHoursCheck): boolean {
        const currentDate = this.getCurrentDate();
        const isWithinDeliveryHours = this.isWithinBusinessHours(data.businessHoursDelivery, currentDate);
        return !data.closed && (data.hasFoodisDelivery || data.hasOwnDelivery) && isWithinDeliveryHours;
    }
    
    public static isToday(day: string): boolean {
        const currentDate = this.getCurrentDate();
        const dayOfWeekMap = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']; // keep this order here because the currentDate has the index associated
        const dayOfWeek = dayOfWeekMap[currentDate.getUTCDay()];    
        return dayOfWeek === day;
    }

    public static isWithinBusinessHours(businessHours: BusinessHours, currentDate: Date): boolean {
        const dayOfWeekMap = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']; // keep this order here because the currentDate has the index associated
        const dayOfWeek = dayOfWeekMap[currentDate.getUTCDay()];    
        let todayHours = null;
        
        switch (dayOfWeek) {
            case BusinessHoursNameEnum.MONDAY: {
                todayHours = businessHours.mon;
                break;
            }
            case BusinessHoursNameEnum.TUESDAY: {
                todayHours = businessHours.tue;
                break;
            }
            case BusinessHoursNameEnum.WEDNESDAY: {
                todayHours = businessHours.wed;
                break;
            }
            case BusinessHoursNameEnum.THURSDAY: {
                todayHours = businessHours.thu;
                break;
            }
            case BusinessHoursNameEnum.FRIDAY: {
                todayHours = businessHours.fri;
                break;
            }
            case BusinessHoursNameEnum.SATURDAY: {
                todayHours = businessHours.sat;
                break;
            }
            case BusinessHoursNameEnum.SUNDAY: {
                todayHours = businessHours.sun;
                break;
            }
        }
        
        if (!todayHours || todayHours?.length === 0) {
            return false; // closed all day
        }

        const currentTime = currentDate.getUTCHours() * 100 + currentDate.getUTCMinutes();

        return todayHours.some(interval => {
            const openTime = parseInt(interval.openTime.replace(':', ''), 10);
            const closeTime = parseInt(interval.closeTime.replace(':', ''), 10);
            return currentTime >= openTime && currentTime <= closeTime;
        });
    }

    /*
        Next available date for pickup / delivery - used in User App
    */
    public static getNextAvailableDatePickup(data: BusinessHoursCheck, locale: string): string {
        return this.getNextAvailableDateForService(data, 'pickup', locale);
    }

    public static getNextAvailableDateDelivery(data: BusinessHoursCheck, locale: string): string {
        return this.getNextAvailableDateForService(data, 'delivery', locale);
    }

    private static getNextAvailableDateForService(data: BusinessHoursCheck, serviceType: 'pickup' | 'delivery', locale: string): string {
        const currentDate = this.getCurrentDate();
        const dayOfWeekMap = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

        for (let i = 0; i < 7; i++) { // Loop over the next 7 days
            const nextDate = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000);
            const dayOfWeek = dayOfWeekMap[nextDate.getUTCDay()];
            const businessHours = serviceType === 'pickup' ? data.businessHoursPickup : data.businessHoursDelivery;

            // Get business hours for the current service type for the day
            const serviceBusinessHours = this.getBusinessHoursForDay(businessHours, dayOfWeek);

            if (!data.closed) {
                const nextAvailableHour = this.findNextAvailableHour(serviceBusinessHours, nextDate, i === 0);

                if (nextAvailableHour) {
                    if (locale === "sr") {
                        locale = "sr-Latn-RS";
                    }
                    let stringDate = nextDate.toLocaleDateString(locale, { weekday: 'long' });
                    stringDate = stringDate.charAt(0).toUpperCase() + stringDate.slice(1)

                    return `${stringDate} ${nextAvailableHour}`;
                }
            }
        }    
        return "";
    }

    private static getBusinessHoursForDay(businessHours: BusinessHours, day: string): BusinessHoursPeriod[] {
        return businessHours[day as keyof BusinessHours];
    }
    
    /*
        This is currently used in the user-app. Returned value is transformed back from UTC to local device date
    */   
    private static findNextAvailableHour(businessHours: BusinessHoursPeriod[], currentDate: Date, checkToday: boolean): string | null {
        if (!businessHours || businessHours.length === 0) return null;

        const currentTime = currentDate.getUTCHours() * 100 + currentDate.getUTCMinutes();

        for (const interval of businessHours) {
            const openTime = parseInt(interval.openTime.replace(':', ''), 10);
            const closeTime = parseInt(interval.closeTime.replace(':', ''), 10);

            if (checkToday) {
                if (currentTime < closeTime) {                    
                    const value = currentTime > openTime ? interval.openTime : interval.openTime; // Return the opening time

                    // Transforming from UTC to browser time:
                    const [utcHours, utcMinutes] = value.split(':').map(Number);
                    const date = new Date(Date.UTC(1970, 0, 1, utcHours, utcMinutes));

                    let localHours = date.getHours();
                    const localMinutes = date.getMinutes();

                    if (this.isDaylightSavingTime()) {
                        localHours += 1;
                    }

                    const formattedHours = String(localHours).padStart(2, '0');
                    const formattedMinutes = String(localMinutes).padStart(2, '0');

                    return formattedHours + ":" + formattedMinutes;                    
                }
            } else {

                // TODO: return converted back from UTC to locale
                return interval.openTime; // First slot of the next available day
            }
        }

        return null; // No time found
    }

    // Duplicate function from timezone.pipe.ts
    private static isDaylightSavingTime(date: Date = new Date()): boolean {
        const year = date.getUTCFullYear();
  
        const startDST = new Date(Date.UTC(year, 2, 31)); // 31 March 00:00 UTC
        while (startDST.getUTCDay() !== 0) {
            startDST.setUTCDate(startDST.getUTCDate() - 1); // Last Sunday from March
        }
  
        const endDST = new Date(Date.UTC(year, 9, 31)); // 31 October 00:00 UTC
        while (endDST.getUTCDay() !== 0) {
            endDST.setUTCDate(endDST.getUTCDate() - 1); // Last Sunday from October
        }
  
        return date >= startDST && date < endDST;
    }

    private static getCurrentDate(): Date {
        return new Date();
    }
}

export interface BusinessHoursCheck {
    businessHoursPickup: BusinessHours;
    businessHoursDelivery: BusinessHours;
    hasFoodisDelivery: boolean;
    hasOwnDelivery: boolean;
    hasPickup: boolean;
    closed: boolean;
}
