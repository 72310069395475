import { Component, OnInit } from '@angular/core';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';
import { Product } from 'libs/shared-models/src/lib/product-type';
import { environment } from 'apps/admin/src/environments/environment';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { SelfAdminService } from '../../services/self.service';
import { UserSelfResponse } from '../../models/user-response';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss'],
})
export class DashboardAdminComponent implements OnInit {

  public Product: typeof Product = Product;

  private verificationRestaurantId: string = "";
  private verificationMessage: string = "";

  public selfData$: Observable<UserSelfResponse> = this.selfDataService.getSelfData$();

  public currentTab$: BehaviorSubject<string> = new BehaviorSubject<string>('restaurants');
  public currentTabIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  
  constructor(
    private apiService: ApiBaseService,
    private toasterService: ToasterService,
    private selfDataService: SelfAdminService,
    private authService: AuthService
  ) {}

  public ngOnInit() {  
  }

  public onIdTextChange($event: any) {
    this.verificationRestaurantId = $event;
  }

  public onMessageChange($event: any) {
    this.verificationMessage = $event;
  }

  // Approve restaurant
  public onApproveClick() {
    const url = environment.API_VERIFICATION_APPROVE.replace("{restaurantId}", this.verificationRestaurantId);
  
    this.apiService.post(url, {
      message: this.verificationMessage
    }).subscribe({
        next: (res: any) => {
            this.toasterService.showSuccess("Approved", "Restaurant " + this.verificationRestaurantId + " was approved");
        },
        error: (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        }
    });
  }

  // Reject restaurant
  public onRejectClick() {        
    const url = environment.API_VERIFICATION_DECLINE.replace("{restaurantId}", this.verificationRestaurantId);
    
    this.apiService.post(url, {
      message: this.verificationMessage
    }).subscribe({
        next: (res: any) => {
            this.toasterService.showInfo("Declined", "Restaurant " + this.verificationRestaurantId + " was rejected");
        },
        error: (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        }
    });
  }
  
  public doLogout() {
      this.authService.doLogout();
  }


  public onTabChange(event: MatTabChangeEvent) {
    this.currentTab$.next(event.tab.ariaLabel)
  }
}
