import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedUiModule } from '@web-foodis/shared-ui';
import { SharedServicesModule } from '@web-foodis/shared-services';
import { environment } from '../environments/environment';
import { LoginAdminRedirectComponent } from './components/login-admin-redirect/login-admin-redirect.component';
import { DashboardAdminComponent } from './components/dashboard-admin/dashboard-admin.component';
import { RestaurantsListComponent } from './components/restaurants-list/restaurants-list.component';
import { UsersListComponent } from './components/users-list/users-list.component';

@NgModule({
  declarations: [
    AppComponent, 
    LoginAdminRedirectComponent, 
    DashboardAdminComponent, 
    RestaurantsListComponent, 
    UsersListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,  
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    SharedServicesModule.forRoot(environment),
    SharedUiModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

