export class BusinessHours {
    public mon: BusinessHoursPeriod[] = [];
    public tue: BusinessHoursPeriod[] = [];
    public wed: BusinessHoursPeriod[] = [];
    public thu: BusinessHoursPeriod[] = [];
    public fri: BusinessHoursPeriod[] = [];
    public sat: BusinessHoursPeriod[] = [];
    public sun: BusinessHoursPeriod[] = [];

    [key: string]: BusinessHoursPeriod[]; // allowing dynamic indexing
}

export class BusinessHoursPeriod {
    public openTime: string = "";
    public closeTime: string = "";
}

export enum BusinessHoursNameEnum {
    MONDAY = "mon",
    TUESDAY = "tue",
    WEDNESDAY = "wed",
    THURSDAY = "thu",
    FRIDAY = "fri",
    SATURDAY = "sat",
    SUNDAY = "sun",
    NONE = "none"
}

export const DAYS_LIST = [
    BusinessHoursNameEnum.MONDAY,
    BusinessHoursNameEnum.TUESDAY,
    BusinessHoursNameEnum.WEDNESDAY,
    BusinessHoursNameEnum.THURSDAY,
    BusinessHoursNameEnum.FRIDAY,
    BusinessHoursNameEnum.SATURDAY,
    BusinessHoursNameEnum.SUNDAY,
]