import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'apps/admin/src/environments/environment';
import { BusinessHoursNameEnum } from 'libs/shared-models/src/lib/restaurant/business-hours';
import { RestaurantResponse, RestaurantVerificationEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-response';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BehaviorSubject } from 'rxjs';
import { AdminUserResponse } from '../../models/admin-user-response';

@Component({
  selector: 'users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {

  public usersList$: BehaviorSubject<AdminUserResponse[]> = new BehaviorSubject<AdminUserResponse[]>([]);
  
  // Map to hold the expanded state of each restaurant
  public expandedMap: Map<string, boolean> = new Map<string, boolean>();

  public userSearchInput: string = "";	
  public selectedSearchType: number = 0;

  constructor(
    private apiService: ApiBaseService,
    private toasterService: ToasterService,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.fetchUsersList();    

    this.usersList$.subscribe(users => {
      users.forEach(user => {
        if (!this.expandedMap.has(user.id)) {
          this.expandedMap.set(user.id, false); // Default to collapsed
        }
      });
    });
  }

  // Restaurants list
  private fetchUsersList() {

    // not really usable - as it's not showing all restaurants which are created / pending / rejected. Only approved ones for now
    let url = environment.API_USERS_SEARCH;

    if (this.userSearchInput) {
      const criteria = this.SEARCH_TYPES[this.selectedSearchType];
      url += "?" + criteria + "=" + this.userSearchInput;
    }

    this.apiService.get(url).subscribe({
        next: (res: any) => {

            const users = res?.results.map((r: AdminUserResponse) => Object.assign(new AdminUserResponse(), r));
            this.usersList$.next(users);
            
        },
        error: (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        }
    });
  }


  public isUserExpanded(id: string): boolean {
    return this.expandedMap.get(id) || false;
  }

  // Function to toggle the expanded state of a restaurant
  public toggleUser(id: string): void {
    const currentState = this.expandedMap.get(id) || false;
    this.expandedMap.set(id, !currentState);
    this.cdRef.detectChanges();
  }

  public clearUserSearchPress() {
    this.onSearchUserUpdate("");
  }
  
  public onSearchUserUpdate(value: string) {
    if (this.userSearchInput === value) {
      return;
    }
    this.userSearchInput = value;
    this.fetchUsersList();
  }

  public getSearchTypes(): string[] {
    return this.SEARCH_TYPES;
  }

  private SEARCH_TYPES = ["name","email", "id"];

  public onSelectSearchType(value: any) {
    this.selectedSearchType = value;
    this.fetchUsersList();
  }
}