
<div class="flex justify-end">
    <div class="pt-5 flex-grow">
        <fs-logo [type]="Product.ADMIN"></fs-logo>
    </div>
    
    <!-- Profile -->
    <div class="mini-profile-container">
        <button mat-button [matMenuTriggerFor]="menu">
            <div class="logged-in-container">
                <!-- Restaurant name -->
                <mat-icon class="account-icon">stars</mat-icon>
                <div class="logged-in-text"> {{ (selfData$ | async)?.name }}
                    <div class="logged-in-email">({{ (selfData$ | async)?.email }})</div>
                </div>
                
                <mat-icon class="ml-2">keyboard_arrow_down</mat-icon>
            </div>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="doLogout()">{{ 'Logout' | translate }}</button>
        </mat-menu>
    </div>
</div>


<div class="m-10">
    <div class="flex flex-col">


        <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="(currentTabIndex$ | async)" class="h-full">

            <!-- Restaurants -->
            <mat-tab [aria-label]="'restaurants'">
                <!-- Tab and title -->
                <ng-template mat-tab-label>    
                    <mat-icon>store</mat-icon>                
                    <div class="pl-2">
                        Restaurants
                    </div>
                </ng-template>

                <!-- Content -->
                <div class="flex flex-col">

                    <!-- RESTAURANT LIST COMPONENT -->
                    <restaurants-list></restaurants-list>
                
                    <!-- Manual approval - if still needed -->
                    <div class="flex mb-5 mt-10"> <mat-icon class="mr-2">build</mat-icon>  Manually approve restaurant </div>
                    <div class="flex flex-row self-start ">
                        <fs-input [label]="'Restaurant ID' | translate" [style]="'full-width'" class="verification-message-id input-text mr-5" (valueUpdate)="onIdTextChange($event)">
                        </fs-input>

                        <fs-input [label]="'Optional message' | translate" [style]="'full-width'" class="verification-message input-text  mr-5" (valueUpdate)="onMessageChange($event)">
                        </fs-input>

                        <fs-button [text]="'Approve' | translate" [type]="'raised-primary'" (onPress)="onApproveClick()"></fs-button>
                        <fs-button [text]="'Decline' | translate" [type]="'raised-primary-warn'" (onPress)="onRejectClick()"></fs-button>
                    </div>   
                </div>
            </mat-tab>

            <!-- Restaurants -->
            <mat-tab [aria-label]="'users'">
                <!-- Tab and title -->
                <ng-template mat-tab-label>   
                    <mat-icon>account_circle</mat-icon>                             
                    <div class="pl-2">
                        Users
                    </div>
                </ng-template>

                <!-- Content -->
                <div class="flex">
                    <users-list></users-list>
                </div>
            </mat-tab>
        </mat-tab-group>         
    </div>  
</div>

