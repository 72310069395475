<!-- All restaurants list -->
<div class="restaurants-list">
    <div class="flex justify-between items-center mb-5 mt-10">
        <!-- Title -->
        <div class="flex items-center">
            <mat-icon class="mr-2">store</mat-icon> 
            Restaurants list: 

            <div class="flex flex-row items-center pl-8">
                <fs-input [label]="'Name'" [style]="'full-width'" class="mr-4 product-search-input"
                    [appearanceStyle]="'outline'" [subscriptSizing]="'dynamic'" [floatLabel]="'always'"
                    (valueUpdate)="onSearchRestaurantUpdate($event)" [inputValue]="restaurantSearchInput"
                    [prefixIcon]="'search'" [trailingIcon]="!!restaurantSearchInput ? 'close' : ''" 
                    (trailingIconPress)="clearRestaurantSearchPress()" [keepTrailingIconSpace]="true">
                </fs-input>
            </div>
        </div>
        <!-- Filter -->
        <div class="flex flex-row items-center">            
            <span class="pr-3">Verification status:</span>
            <mat-button-toggle-group name="verificationStatus" [formControl]="verificationStatus" (change)="filterVerificationStatusChange($event)">
                <mat-button-toggle [value]="'ALL'">All</mat-button-toggle>
                <mat-button-toggle [value]="RestaurantVerificationEnum.VERIFIED">Verified</mat-button-toggle>                
                <mat-button-toggle [value]="RestaurantVerificationEnum.VERIFICATION_REQUESTED">Requested</mat-button-toggle>
                <mat-button-toggle [value]="RestaurantVerificationEnum.VERIFICATION_DECLINED">Declined</mat-button-toggle>
                <mat-button-toggle [value]="RestaurantVerificationEnum.NEW">New</mat-button-toggle>                
            </mat-button-toggle-group>
            
        </div>
    </div>
    <ng-container *ngFor="let restaurant of (restaurantsList$ | async)">

        <!-- Each restaurant -->
        <div class="restaurant-item">
            <!-- Dropdown accordion Business hours-->
            <mat-accordion>                
                <mat-expansion-panel (opened)="toggleRestaurant(restaurant.id)" (closed)="toggleRestaurant(restaurant.id)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>  
                            <div class="expansion-title">
                                <div class="expansion-logo">
                                    <img class="expansion-image-src" [src]="restaurant.logoImageUrl">
                                </div>
                                {{ restaurant.name }} 
                            </div>
                            
                        </mat-panel-title>
                        <mat-panel-description class="expansion-description">
                            <ng-container *ngIf="restaurant.verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED">
                                <div class="bg-orange-500 text-white pl-3 pr-3 mr-3 text-sm">
                                    action required
                                </div>
                            </ng-container>
                            <div [ngClass]="{
                                'green' : restaurant.verificationStatus === RestaurantVerificationEnum.VERIFIED,
                                'red' : restaurant.verificationStatus === RestaurantVerificationEnum.VERIFICATION_DECLINED,
                                'text-orange-500' : restaurant.verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED,
                                'text-blue-900' : restaurant.verificationStatus === RestaurantVerificationEnum.NEW
                            }">
                                {{ restaurant.verificationStatus }}
                            </div>,

                            <div class='ml-1' [ngClass]="{'green' : !restaurant.closed, 'gray': restaurant.closed}">
                                {{ restaurant.closed ? "Closed" : "Open" }}
                            </div>
                            
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                                    
                    <!-- CONTENT when expanded-->

                    <!-- APPROVE / DECLINE -->
                    <ng-container *ngIf="restaurant.verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED">
                        <div class="flex flex-col mt-4">                                
                            <div class="flex text-orange-500 mb-3"> <mat-icon class="mr-2">add_alert</mat-icon> Approve restaurant "{{ restaurant.name }}"? </div>
                            <div class="flex flex-row self-start ">

                                <fs-input [label]="'Approve/Decline message' | translate" [style]="'full-width'" class="verification-message input-text  mr-5" (valueUpdate)="onMessageChange($event, restaurant.id)">
                                </fs-input>

                                <fs-button class="mt-3" [text]="'Approve' | translate" [type]="'raised-primary'" (onPress)="onApproveClick(restaurant.id)"></fs-button>
                                <fs-button class="mt-3" [text]="'Decline' | translate" [type]="'raised-primary-warn'" (onPress)="onRejectClick(restaurant.id)"></fs-button>
                            </div> 
                        </div>
                    </ng-container>
                    
                    <!-- Logo & background -->
                    <div class="logo-background-container">
                        <div class="r-info-logo-background">                                    
                            <!-- Logo -->
                            <div class="r-info-logo-container">     
                                <ng-container *ngIf="restaurant.logoImageUrl">
                                    <a [href]="restaurant.logoImageUrl || '#'" target="_blank">
                                        <div class="restaurant-logo-image-filled">
                                            <img class="logo-image-src" [src]="restaurant.logoImageUrl || ''"/>
                                        </div>
                                    </a>
                                </ng-container>
                            </div>           
                            <!-- Background -->
                            <div class="r-info-background-container">
                                <ng-container *ngIf="restaurant.mainImageUrl; else missingBackgroundImage">
                                    <a [href]="restaurant.mainImageUrl || '#'" target="_blank">
                                        <div class="restaurant-image-filled">
                                            <img [src]="restaurant.mainImageUrl || ''"/>
                                        </div>
                                    </a>
                                </ng-container>     
                                <ng-template #missingBackgroundImage>
                                    <div class="missing-restaurant-image-container">
                                        {{ "restaurant_image_not_provided" | translate }}
                                    </div>
                                </ng-template>  
                            </div>     
                        </div>
                    </div>
                    

                    <!-- 3 columns -->                    
                    <div class="other-details-container">

                        <!-- Left side -->
                        <div class="other-details-left">
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">ID:</div>
                                <div>
                                    {{ restaurant.id }}
                                </div>
                            </div>  
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Slug:</div>
                                <div>
                                    {{ restaurant.slug }}
                                </div>
                            </div>  

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Verification status:</div>
                                <div>
                                    {{ restaurant.verificationStatus }}
                                </div>
                            </div>  

                              
                            
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Closed:</div>
                                <div>
                                    {{ restaurant.closed }}
                                </div>
                            </div>
                            
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Company:</div>
                                <div>
                                    <ng-container *ngIf="restaurant.companyName; else dataNotProvided ">
                                        {{ restaurant.companyName}}
                                    </ng-container>
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Phone:</div>
                                <div>
                                    <ng-container *ngIf="restaurant.phoneNumber; else dataNotProvided">
                                        {{ restaurant.phoneNumber}}
                                    </ng-container>
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Email:</div>
                                <div>
                                    <ng-container *ngIf="restaurant.email; else dataNotProvided">
                                        {{ restaurant.email}}
                                    </ng-container>
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Website:</div>
                                <div>
                                    <ng-container *ngIf="restaurant.website; else dataNotProvided ">
                                        {{ restaurant.website}}
                                    </ng-container>
                                </div>
                            </div>
                            
                        </div>
                
                        <!-- Right side -->
                        <div class="other-details-right">
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Pick-up:</div>
                                <div>
                                    {{ restaurant.hasPickup }}
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Own restaurant delivery:</div>
                                <div>
                                    {{ restaurant.hasOwnDelivery }}
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Foodis delivery:</div>
                                <div>
                                    {{ restaurant.hasFoodisDelivery }}
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Time delivery range:</div>
                                <div>
                                    <ng-container *ngIf="restaurant.deliveryTimeRange; else dataNotProvided">
                                        {{ restaurant.deliveryTimeRange}}
                                    </ng-container>
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Minimum order:</div>
                                <div>
                                    <ng-container *ngIf="restaurant.minOrderAmount; else dataNotProvided">
                                        {{ ((restaurant.minOrderAmount || 0) * 100) | format_cents: true }}
                                    </ng-container>
                                </div>
                            </div>                                    

                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Delivery radius in m:</div>
                                <div>
                                    {{ restaurant.deliveryRadiusMeters }}
                                </div>
                            </div> 
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Timezone:</div>
                                <div>
                                    {{ restaurant.timeZone }}
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Cuisines:</div>
                                <div>
                                    {{ restaurant.categoryList }}
                                </div>
                            </div>    

                        </div>

                        <!-- Right side x 2-->
                        <div class="other-details-right">
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">VAT:</div>
                                <div>
                                    <ng-container *ngIf="restaurant.vatNumber; else dataNotProvided ">
                                        {{ restaurant.vatNumber}}
                                    </ng-container>
                                </div>
                            </div>
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">IBAN:</div>
                                <div>
                                    {{ restaurant.ibanAccount }}
                                </div>
                            </div>    
                            
                        </div>

                          
                        
                        <ng-template #dataNotProvided>
                            <span class="not-provided-text">Not provided</span>
                        </ng-template>                                
                    </div>

                    
                    <!-- Description -->
                    <div class="logo-ids">
                        <div class="other-details-left">
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Description:</div>
                                <div>
                                    {{ restaurant.description }}
                                </div>
                            </div>  
                        </div>
                    </div>

                    <!-- ADDRESS -->   
                    <div class="pl-1">                                
                        <div class="flex flex-row pt-2">
                            <!-- Address -->
                            <div class="subtitle-text mr-4">
                                Restaurant address:
                            </div>
                            <!-- Data -->
                            <div class="full-address">
                                {{ restaurant?.address?.streetName  }} {{ restaurant?.address?.streetNumber  }},
                                {{ restaurant?.address?.district  }} {{ restaurant?.address?.postalCode  }}, {{ restaurant?.address?.city  }}, {{ restaurant?.address?.country }}
                            </div>
                        </div>
                    
                    
                        <div class="flex flex-row pt-3">
                            <!-- Address -->
                            <div class="subtitle-text mr-4">
                                Invoice address:
                            </div>
                            <!-- Data -->
                            <div class="full-address">
                                {{ restaurant?.invoiceAddress?.streetName  }} {{ restaurant?.invoiceAddress?.streetNumber  }},
                                {{ restaurant?.invoiceAddress?.district  }} {{ restaurant?.invoiceAddress?.postalCode  }}, {{ restaurant?.invoiceAddress?.city  }}, {{ restaurant?.invoiceAddress?.country }}
                            </div>
                        </div>       
                    </div>   
                    

                    <!-- Verification documents -->
                    <div class="logo-ids">
                        <div class="other-details-left">
                            <div class="other-details-item">
                                <div class="subtitle-text pr-2">Verification documents:</div>
                                <div class="verification-document">                                            
                                    <ng-container *ngFor="let document of restaurant.documents">
                                        <span>[
                                                Id: 
                                                    <span class="document-details">{{ document.id }}</span>, 
                                                Blob Id: 
                                                    <span class="document-details">{{ document.blobId }}</span>,
                                                Link: <a class="document-url" target="_blank" [href]="document.url">{{ document.name }}</a>
                                            ]
                                        </span>
                                        
                                    </ng-container>
                                </div>
                            </div>    
                        </div>
                    </div>

                    <div class="other-details-item">
                        <div class="subtitle-text pr-2">Verification message:</div>
                        <div>
                            {{ restaurant.verificationMessage }}
                        </div>
                    </div>  
                
                    
                    <!-- Opening hours: -->

                    <div class="flex flex-col pl-1 pt-3">
                        <div class="subtitle-text pr-2">Business hours:</div>
                        <div class="w-full flex-col">
                            <ng-container *ngFor="let hours of (getHours(restaurant))">
                                <div class="r-info-hours-row">
                                    <div class="r-info-hours-col-1"> {{ hours.day }} </div>
                                    <div class="r-info-hours-col-2"> 
                                        <ng-container *ngIf="(hours.pickupHours || []).length === 0">
                                            <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                                        </ng-container>
                                        <ng-container *ngFor="let item of (hours.pickupHours || [])">
                                            <div>{{ item.openTime + " - " + item.closeTime }}</div>
                                        </ng-container>
                                    </div>
                                    <div class="r-info-hours-col-3">
                                        <ng-container *ngIf="(hours.deliveryHours || []).length === 0">
                                            <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                                        </ng-container>
                                        <ng-container *ngFor="let item of (hours.deliveryHours || [])">
                                            <div>{{ item.openTime + " - " + item.closeTime }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>  
    
                        <ng-template #closedHours>
                            <div class="flex items-center">
                                Closed
                            </div>
                        </ng-template>
                    </div>  

                    


                     

                </mat-expansion-panel>
            </mat-accordion>
        </div>                
    </ng-container>
</div>
