import { Route } from '@angular/router';
import { LoginAdminRedirectComponent } from './components/login-admin-redirect/login-admin-redirect.component';
import { AuthGuard } from 'libs/shared-services/src/lib/auth-guard.service';
import { DashboardAdminComponent } from './components/dashboard-admin/dashboard-admin.component';

export const appRoutes: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    { path: 'login', component: LoginAdminRedirectComponent},
    { path: 'dashboard',
        component: DashboardAdminComponent,
        canActivate: [AuthGuard]
    }]
