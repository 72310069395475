import {Inject, Injectable} from '@angular/core';
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {BehaviorSubject, Observable} from "rxjs";
import { environment } from '../../environments/environment';
import { UserSelfResponse } from '../models/user-response';
import { Product } from 'libs/shared-models/src/lib/product-type';

@Injectable({
    providedIn: 'root',
})
export class SelfAdminService {

    private userData: BehaviorSubject<UserSelfResponse> = new BehaviorSubject<UserSelfResponse>(new UserSelfResponse());

    constructor(
        @Inject('env') private environment: any,
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
    ) {

    }

    public fetchSelfDataAPI() {
        this.apiService.get(environment.API_USER_SELF).subscribe({
            next: (res: any) => {
                let data = Object.assign(new UserSelfResponse(), res);
                this.setData(data);
                this.redirectToSelfProduct();
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
                window.location.href = environment.PRODUCT_URLS.ACCOUNT;
            }
        });
    }

    private setData(data: UserSelfResponse) {
        this.userData.next(data);
    }

    public getSelfData$(): Observable<UserSelfResponse> {
        return this.userData.asObservable();
    }

    public getSelfData(): UserSelfResponse {
        return this.userData.getValue();
    }

    private redirectToSelfProduct() {
        const data = this.getSelfData();
        const product = data.product.toLowerCase();

        // ignore if product is the expected one (restaurant, as we're in the restaurant service / subdomain)
        if (product === Product.ADMIN) {
            return;
        } else {
            // redirect the user to the correct product
            let redirectUrl = "";
            switch (product) {
                case Product.USER_APP: {
                    redirectUrl = this.environment.PRODUCT_URLS.USER_APP;
                    break;
                }
                case Product.DRIVER: {
                    redirectUrl = this.environment.PRODUCT_URLS.DRIVER;
                    break;
                }
                case Product.RESTAURANT: {
                    redirectUrl = this.environment.PRODUCT_URLS.RESTAURANT;
                    break;
                }
                default: {
                    redirectUrl = this.environment.PRODUCT_URLS.LANDING_PAGE;
                    break;
                }
            }
            // Comment this line when you need to test with localhost development - otherwise you'll always get redirected to deployed version
            window.location.href = redirectUrl;
        }
    }
}

